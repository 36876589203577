import React from 'react'

import {
  translated,
  useTranslation,
} from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Cancel = translated('app.project')('MemberInvite.Cancel')

/**
 */
export const Submit = translated('app.project')('MemberInvite.Submit')

/**
 */
export const Title = translated('app.project')('MemberInvite.Title')

/**
 */
export const CanView = translated('app.project')('MemberInvite.CanView')

/**
 */
export const CanEdit = translated('app.project')('MemberInvite.CanEdit')

/**
 */
export const RequiredLabel = translated('app.project')(
  'MemberInvite.RequiredLabel',
)

/**
 */
export const useLiterals = () => {
  const { t } = useTranslation(['app.project', 'module.project.member'])

  const formatFieldLabel = (fieldName, ...options) =>
    t(`module.project.member:Field.Labels.${fieldName}`, ...options)

  const formatPlaceholder = (key, ...options) =>
    t(`module.project.member:Field.Placeholder.${key}`, ...options)

  const formatRoles = (key, ...options) =>
    t(`app.project:MemberInvite.Roles.${key}`, ...options)

  const formatFormError = (key, ...options) =>
    t(`module.project.member:${key}`, ...options)

  const formatFieldError = (key, ...options) =>
    t(`module.project.member:Form.Error.${key}`, ...options)

  return {
    formatFieldLabel,
    formatPlaceholder,
    formatRoles,
    formatFormError,
    formatFieldError,
  }
}
