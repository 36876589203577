import React from 'react'

import * as ProjectLinks from '@arch-log/webapp.shared/Project.v2/Links'
import * as Styled from './Styled'
import * as Literals from './Literals'

import * as styles from './styles.module.scss'

/**
 */
export const Title = () => {
  return (
    <Styled.Title>
      <Literals.Title />
    </Styled.Title>
  )
}

/**
 */
export const BackToList = () => (
  <ProjectLinks.ToMemberList className={styles.BackButton}>
    <Literals.BackToList />
  </ProjectLinks.ToMemberList>
)

/**
 */
export const InviteMore = ({ onClick }) => {
  return (
    <Styled.InviteMore onClick={onClick}>
      <Literals.InviteMore />
    </Styled.InviteMore>
  )
}

export const CompleteMessage = () => {
  return <Literals.CompleteMessage />
}
