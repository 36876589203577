import React from 'react'

import * as ProjectMemberRoles from '@arch-log/webapp.modules/project.member.v2/Roles'

/**
 */
export const Default = ({ next }) => {
  React.useEffect(() => {
    next({
      defaultValues: {
        role: ProjectMemberRoles.Editor,
      },
    })
  }, [])

  return null
}
