import React from 'react'

import * as InviteForm from '@arch-log/webapp.modules/project.member.v2/components/InviteForm'
import * as Containers from './Containers'
import * as Layout from './Layout'

import * as Fields from './Fields'

/**
 */
export const Default = ({ next, context }) => {
  return (
    <Layout.Wrapper>
      <Layout.Header>
        <Containers.Title />
      </Layout.Header>
      <InviteForm.Initializer
        onSubmit={(data) => next({ formData: data })}
        defaultValues={context?.defaultValues}
        values={context?.formData}
        errors={context?.errors}
      >
        <Layout.FormContainer>
          <Containers.InputRequiredLabel />
          <Containers.Errors />
          <Fields.Email />
          <Fields.EmailConfirm />
          <Fields.Permissions />
          <Layout.ButtonContainer>
            <Containers.Submit />
            <Containers.Cancel />
          </Layout.ButtonContainer>
        </Layout.FormContainer>
      </InviteForm.Initializer>
    </Layout.Wrapper>
  )
}
