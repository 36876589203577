import React from 'react'

import { BaseSubFlow } from './SubFlow'
import * as Layout from './Layout'

/**
 */
export const Default = ({ projectId, next, prev, context }) => {
  return (
    <Layout.Wrapper>
      <BaseSubFlow
        projectId={projectId}
        context={context}
        onComplete={() => next()}
        prev={prev}
      />
    </Layout.Wrapper>
  )
}
