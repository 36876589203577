import React from 'react'

import { useNavigate } from '@arch-log/webapp.lib/react.hook'

import * as Routes from '@arch-log/webapp.shared/Routes'
import * as Containers from './Containers'
import * as Layout from './Layout'

/**
 */
export const Default = ({ projectId }) => {
  const { redirectTo } = useNavigate()

  return (
    <Layout.Wrapper>
      <Layout.Header>
        <Containers.Title />
      </Layout.Header>
      <Layout.Body>
        <Containers.CompleteMessage />
      </Layout.Body>
      <Layout.Footer>
        <Containers.InviteMore
          onClick={() => {
            redirectTo(Routes.Project.toInviteMember({ project: projectId }))
          }}
        />
        <Containers.BackToList />
      </Layout.Footer>
    </Layout.Wrapper>
  )
}
