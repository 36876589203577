import React from 'react'

import { createFlow } from '@arch-log/webapp.atomics/components/Flow'

import * as ProjectMember from '@arch-log/webapp.modules/project.member.v2/components'
import * as Steps from './Steps'

/**
 */
export const BaseFlow = createFlow([
  Steps.Init,
  Steps.Input,
  Steps.Progress,
  Steps.Complete,
])

/**
 */
export const Flow = ({ projectId, ...props }) => {
  return (
    <ProjectMember.Remote.Mutations.InviteEntry.Initializer
      projectId={projectId}
    >
      <BaseFlow projectId={projectId} {...props} />
    </ProjectMember.Remote.Mutations.InviteEntry.Initializer>
  )
}
