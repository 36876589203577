import React from 'react'

import * as PageHeadTemplates from '@arch-log/webapp.shared/Page/Head'
import * as Partial from 'partials/MemberInvitePage'

/**
 */
export const Head = ({ pageContext }) => {
  return <PageHeadTemplates.Language language={pageContext?.language} />
}

/**
 */
const Page = ({ id, ...props }) => <Partial.Flow projectId={id} {...props} />

export default Page
