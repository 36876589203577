import React from 'react'

import * as ProjectContentLayout from 'components/ProjectContentLayout'

import * as styles from './layout.module.scss'

/**
 */
export const Wrapper = ({ children }) => (
  <ProjectContentLayout.Container>
    <div className={styles.Wrapper}>{children}</div>
  </ProjectContentLayout.Container>
)

/**
 */
export const Header = ({ children }) => (
  <div className={styles.Header}>{children}</div>
)

/**
 */
export const FormContainer = ({ children }) => (
  <div className={styles.FormContainer}>{children}</div>
)

/**
 */
export const FieldContainer = ({ children }) => (
  <div className={styles.FieldContainer}>{children}</div>
)

/**
 */
export const FieldLabel = ({ children }) => (
  <div className={styles.FieldLabel}>{children}</div>
)

/**
 */
export const FieldInput = ({ children }) => <>{children}</>

/**
 */
export const ButtonContainer = ({ children }) => (
  <div className={styles.ButtonContainer}>{children}</div>
)
