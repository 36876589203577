import React from 'react'

import { useAsync } from 'react-use'

import * as ProjectMember from '@arch-log/webapp.modules/project.member.v2/components'
import * as Containers from './Containers'

/**
 */
export const Progress = ({ context, next, prev }) => {
  const {
    inviteEntry,
  } = ProjectMember.Remote.Mutations.InviteEntry.useMutation()

  useAsync(async () => {
    try {
      await inviteEntry({
        variables: {
          data: ProjectMember.InviteForm.Helper.formToModel(context?.formData),
        },
        onCompleted: (data) => {
          next()
        },
        onError: (error) => {
          switch (error.message) {
            default:
              prev({
                errors: { _form: `Form.Error.Failed` },
              })
          }
        },
      })
    } catch (err) {
      prev({ errors: { _form: 'Form.Error.Failed' } })
    }
  }, [])

  return <Containers.ProgressBar />
}

/**
 */
export const Complete = ({ next }) => {
  React.useEffect(() => {
    next()
  }, [])

  return null
}
