import React from 'react'

import * as InviteForm from '@arch-log/webapp.modules/project.member.v2/components/InviteForm'
import * as ProjectLinks from '@arch-log/webapp.shared/Project.v2/Links'
import * as Styled from './Styled'
import * as Literals from './Literals'
import * as styles from './styles.module.scss'

/**
 */
export const InputRequiredLabel = () => {
  return (
    <Styled.InputRequiredLabel>
      <Literals.RequiredLabel />
    </Styled.InputRequiredLabel>
  )
}

/**
 */
export const Title = () => {
  return (
    <Styled.Title>
      <Literals.Title />
    </Styled.Title>
  )
}

/**
 */
export const Submit = () => (
  <InviteForm.Submit
    render={() => (
      <Styled.SubmitButton>
        <Literals.Submit />
      </Styled.SubmitButton>
    )}
  />
)

/**
 */
export const Cancel = () => (
  <ProjectLinks.ToMemberList className={styles.CancelButton}>
    <Literals.Cancel />
  </ProjectLinks.ToMemberList>
)

/**
 */
export const Errors = ({ ...props }) => {
  const { formatFormError } = Literals.useLiterals()

  return <InviteForm.Errors.Form formatMessage={formatFormError} {...props} />
}
