import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Title = ({ children }) => (
  <span className={styles.Title}>{children}</span>
)

/**
 */
export const CompleteMessage = ({ children }) => <>{children}</>

/**
 */
export const InviteMore = ({ children, onClick }) => (
  <div onClick={onClick} className={styles.InviteButton}>
    {children}
  </div>
)
