import React from 'react'

import * as InviteForm from '@arch-log/webapp.modules/project.member.v2/components/InviteForm'
import * as Layout from './Layout'
import * as Literals from './Literals'
import * as Styled from './Styled'
import * as styles from './styles.module.scss'

/**
 */
export const createField = (name, InputRenderer, required = true) => () => {
  const { formatFieldLabel } = Literals.useLiterals()

  return (
    <Layout.FieldContainer>
      <Layout.FieldLabel>
        {formatFieldLabel(name)}
        <Styled.RequiredIcon required={required} />
      </Layout.FieldLabel>
      <Layout.FieldInput>
        <InputRenderer />
      </Layout.FieldInput>
    </Layout.FieldContainer>
  )
}

/**
 */
export const Email = createField('Email', () => {
  const { formatPlaceholder, formatFieldError } = Literals.useLiterals()

  return (
    <>
      <InviteForm.Errors.Email formatMessage={formatFieldError} />
      <InviteForm.Fields.Email
        className={styles.FieldInputItem}
        formatPlaceholder={formatPlaceholder}
      />
    </>
  )
})

/**
 */
export const EmailConfirm = createField('EmailConfirm', () => {
  const { formatPlaceholder, formatFieldError } = Literals.useLiterals()

  return (
    <>
      <InviteForm.Errors.EmailConfirm formatMessage={formatFieldError} />
      <InviteForm.Fields.EmailConfirm
        className={styles.FieldInputItem}
        formatPlaceholder={formatPlaceholder}
      />
    </>
  )
})

/**
 */
export const Permissions = createField('Permissions', () => {
  const { formatRoles, formatFieldError } = Literals.useLiterals()

  return (
    <>
      <InviteForm.Errors.Roles formatMessage={formatFieldError} />
      <InviteForm.Fields.Roles
        formatOption={formatRoles}
        className={styles.FieldSelectItem}
      />
    </>
  )
})
