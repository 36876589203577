import React from 'react'

import { translated } from '@arch-log/webapp.lib/TranslationHelper'

/**
 */
export const Title = translated('app.project')('MemberInvite.Title')

/**
 */
export const CompleteMessage = translated('app.project')(
  'MemberInvite.Complete.Message',
)

/**
 */
export const BackToList = translated('app.project')(
  'MemberInvite.Complete.BackToList',
)

/**
 */
export const InviteMore = translated('app.project')(
  'MemberInvite.Complete.InviteMore',
)
