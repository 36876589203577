import React from 'react'

import * as styles from './styles.module.scss'

/**
 */
export const Title = ({ children }) => (
  <span className={styles.Title}>{children}</span>
)

/**
 */
export const InputRequiredLabel = ({ children }) => {
  return <div className={styles.InputRequiredLabel}>{children}</div>
}

/**
 */
export const RequiredIcon = ({ required = true }) => (
  <span className={styles.RequiredIcon}>{required ? '*' : null}</span>
)

/**
 */
export const SubmitButton = ({ children }) => (
  <button type="submit" className={styles.SubmitButton}>
    {children}
  </button>
)

/**
 */
export const CancelButton = ({ children }) => (
  <div className={styles.CancelButton}>{children}</div>
)
