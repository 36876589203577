import React from 'react'

import { Page as PageLoader } from '@arch-log/webapp.atomics/components/Loader'

import * as Literals from './Literals'

/**
 */
export const ProgressBar = () => {
  return (
    <PageLoader>
      <Literals.Progress />
    </PageLoader>
  )
}
