import React from 'react'

import * as InitStep from './InitStep'
import * as InputStep from './InputStep'
import * as ProgressStep from './ProgressStep'
import * as CompleteStep from './CompleteStep'

/**
 */
export const Init = InitStep.Default

/**
 */
export const Input = InputStep.Default

/**
 */
export const Progress = ProgressStep.Default

/**
 */
export const Complete = CompleteStep.Default
